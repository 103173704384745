import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home";
import TermsAndConditions from "./TermsAndConditions";
import DataPrivacy from "./DataPrivacy";

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Router>
            <div style={styles.navbar}>
                <div style={styles.burgerMenu} onClick={toggleMenu}>
                    <div style={styles.burgerLine}></div>
                    <div style={styles.burgerLine}></div>
                    <div style={styles.burgerLine}></div>
                </div>

                <div style={{ ...styles.menu, display: isOpen ? "block" : "none" }}>
                    <Link to="/" style={styles.link} onClick={toggleMenu}>Home</Link>
                    <Link to="/terms" style={styles.link} onClick={toggleMenu}>Terms and Conditions</Link>
                    <Link to="/privacy" style={styles.link} onClick={toggleMenu}>Data Privacy</Link>
                </div>
            </div>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<DataPrivacy />} />
            </Routes>

        </Router>
    );
}

const styles = {
    navbar: {
        backgroundColor: "#1ACEA3",
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
    },
    burgerMenu: {
        width: "35px",
        height: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
    },
    burgerLine: {
        width: "100%",
        height: "4px",
        backgroundColor: "#fff",
    },
    menu: {
        position: "absolute",
        top: "50px",
        left: 0,
        right: 0,
        backgroundColor: "#1ACEA3",
        zIndex: 1,
        textAlign: "center",
    },
    link: {
        color: "#fff",
        display: "block",
        padding: "10px 0",
        textDecoration: "none",
        fontSize: "18px",
    },
};

export default App;
