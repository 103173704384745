import React from "react";

function Home() {
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.title}>Localeyez</h1>
                <p style={styles.description}>
                    Discover and connect with the hidden gems of your city with Localeyez.
                </p>
                <p style={styles.description}>
                    Localeyez empowers individuals to explore nearby experiences, from quaint cafés to vibrant community events, fostering a deeper connection with their surroundings.
                </p>
                <p style={styles.description}>
                    Post your discoveries, find nearby favorites, and dive into the authentic local scene using our distance-based filters. It's never been easier to explore what's around you, creating richer, more meaningful local experiences.
                </p>
                <p style={styles.description}>
                    COMING SOON
                </p>
            </div>

            {/* Whitepaper Section */}
            <div style={styles.whitepaperContainer}>
                <h2 style={styles.whitepaperTitle}>Our Philosophy</h2>
                <div style={styles.whitepaper}>
                    <div style={styles.whitepaperContent}>
                        <h3 style={styles.whitepaperSubheading}>Bridging Communities Through Proximity</h3>
                        <p style={styles.whitepaperText}>
                            In today's world, technology often brings people closer online but pulls them apart in physical spaces. <strong>Localeyez</strong> seeks to reverse this trend by fostering authentic, localized connections. By harnessing the power of geolocation, the app encourages users to engage meaningfully with their immediate surroundings—whether it's discovering hidden gems, attending local events, or participating in community-driven conversations.
                        </p>
                    </div>
                    <div style={styles.whitepaperContent}>
                        <h3 style={styles.whitepaperSubheading}>Discovery and Engagement</h3>
                        <p style={styles.whitepaperText}>
                            Users can post about their favorite places or experiences, sharing them with others nearby. The distance slider filter (1-10 km) ensures that posts and events align with what’s truly accessible, helping people discover their cities one block at a time.
                        </p>
                    </div>
                    <div style={styles.whitepaperContent}>
                        <h3 style={styles.whitepaperSubheading}>Empowering Event Organizers</h3>
                        <p style={styles.whitepaperText}>
                            Local event organizers can post geolocated events, making it easy for those in proximity to find and participate in community gatherings, boosting attendance and engagement.
                        </p>
                    </div>
                    <div style={styles.whitepaperContent}>
                        <h3 style={styles.whitepaperSubheading}>Fostering Interaction</h3>
                        <p style={styles.whitepaperText}>
                            By bringing people together in shared spaces, Localeyez fosters real, in-person connections—whether through spontaneous meetups or organized events—building stronger bonds within neighborhoods.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "rgba(0, 50, 59, 1)", // Dark background
        padding: "20px", // Padding for smaller screens
    },
    content: {
        position: "relative",
        textAlign: "center",
        color: "#fff",
        maxWidth: "90%", // Adjusted for mobile responsiveness
        margin: "0 auto",
    },
    title: {
        fontFamily: "Geo",
        fontSize: "60px", // Base font size
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: "4px",
        marginBottom: "20px",
        textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)", // Text shadow for effect
        animation: "fadeIn 2s ease-in-out",
        // Responsive font size for smaller screens
        '@media (max-width: 768px)': {
            fontSize: "40px",
            letterSpacing: "2px",
        },
        '@media (max-width: 480px)': {
            fontSize: "32px",
            letterSpacing: "1px",
        },
    },
    description: {
        fontSize: "24px", // Base font size
        marginBottom: "40px",
        lineHeight: "1.6",
        textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
        animation: "fadeIn 2s ease-in-out 1s",
        // Responsive font size for smaller screens
        '@media (max-width: 768px)': {
            fontSize: "18px",
            marginBottom: "30px",
        },
        '@media (max-width: 480px)': {
            fontSize: "16px",
            marginBottom: "20px",
        },
    },
    button: {
        textAlign: "center",
        fontSize: "20px",
        color: "#fff",
        backgroundColor: "#1ACEA3",
        padding: "15px 30px",
        borderRadius: "30px",
        textDecoration: "none",
        transition: "background-color 0.3s",
        border: "none",
        cursor: "pointer",
        animation: "fadeIn 2s ease-in-out 1.5s",
        // Adjust padding and font size for smaller screens
        '@media (max-width: 768px)': {
            fontSize: "16px",
            padding: "12px 24px",
        },
        '@media (max-width: 480px)': {
            fontSize: "14px",
            padding: "10px 20px",
        },
    },

    // Whitepaper section styles
    whitepaperContainer: {
        backgroundColor: "rgba(255, 255, 255, 0.9)", // Light background for contrast
        textAlign: "center",
        padding: "10px"
    },
    whitepaperTitle: {
        fontSize: "36px",
        fontWeight: "bold",
        color: "#00323B",
        marginBottom: "40px",
        animation: "fadeIn 1.5s ease-in-out",
    },
    whitepaper: {
        maxWidth: "800px",
        margin: "0 auto",
    },
    whitepaperContent: {
        marginBottom: "40px",
        animation: "fadeInUp 1s ease-in-out",
    },
    whitepaperSubheading: {
        fontSize: "28px",
        fontWeight: "600",
        color: "#1ACEA3",
        marginBottom: "10px",
    },
    whitepaperText: {
        fontSize: "18px",
        lineHeight: "1.8",
        color: "#00323B",
    },

    // Animations
    '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    '@keyframes fadeInUp': {
        '0%': { opacity: 0, transform: 'translateY(20px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' }
    }
};

export default Home;
