import React from "react";

function TermsAndConditions() {
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Terms and Conditions</h1>
            <p style={styles.text}>
                These terms and conditions outline the rules and regulations for the use of Localeyez's Website.
                By accessing this website, we assume you accept these terms and conditions. Do not continue to use
                Localeyez if you do not agree to all of the terms and conditions stated on this page.
            </p>
            <p style={styles.text}>
                <iframe style={styles.iframe} src={`${process.env.PUBLIC_URL}/terms.html`} title="Terms and Conditions"></iframe>
            </p>

        </div>
    );
}



const styles = {
    container: {
        padding: "40px",
        textAlign: "left",
    },
    title: {
        fontSize: "36px",
        fontWeight: "bold",
        color: "#333",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        color: "#666",
    },
    iframe: {
        width: '100%',
        height: '700px'
    }
};

export default TermsAndConditions;
