import React from "react";

function DataPrivacy() {
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Data Privacy</h1>
            <p style={styles.text}>
                At Localeyez, we value your privacy. This Privacy Policy outlines the types of personal information that we collect
                and how it is used and protected.
            </p>
            <p style={styles.text}>
                <iframe style={styles.iframe} src={"https://www.iubenda.com/privacy-policy/35537477"} title="Data Privacy"></iframe>
            </p>
        </div>
    );
}

const styles = {
    container: {
        padding: "40px",
        textAlign: "left",
    },
    title: {
        fontSize: "36px",
        fontWeight: "bold",
        color: "#333",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        color: "#666",
    },
    iframe: {
        width: '100%',
        height: '700px'
    }
};

export default DataPrivacy;
